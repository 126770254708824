@media (max-width: 1880px) {
}

@media (max-width: 1780px) {
}

@media (max-width: 1680px) {
}

@media (max-width: 1560px) {
}

@media (max-width: 1480px) {
  #content {
    padding: 50px 0px 46px 32px;
  }
}

@media (max-width: 1367px) {
  .login-logo.mb-32 {
    margin-bottom: 18px !important;
  }
  .login-logo {
    width: 55px;
    height: 60px;
  }
  .formBox {
    padding: 40px 29px 50px;
  }
  .f36 {
    color: #fff;
    font-family: "Aneba Neue";
    font-size: 26px;
  }
  .loginlogo img {
    width: 51px;
  }
  .theme-container {
    max-width: 1100px;
  }
  #content {
    padding: 36px 0px 46px 35px;
  }
  .filterList {
    right: 72px;
    left: auto;
  }
}

@media (max-width: 1280px) {
  #content {
    padding: 36px 0px 46px 1px;
  }
}
@media (max-width: 1199px) {
  .headerLogin {
    padding: 14px 0;
  }
  .theme-container {
    max-width: 961px;
  }
  .loginlogo img {
    width: 61px;
  }
  .menu-open {
    margin-left: 0 !important;
    z-index: 999999999 !important;
    display: block !important;
    width: 400px !important;
  }
  .menu-close {
    display: none !important;
  }
  #sidebar {
    z-index: 999 !important;
  }
  /* #sidebar {
        margin-left: -300px;
    } */
  .menu_icon {
    display: inherit;
  }
  #sidebar.active {
    margin-left: 0;
  }
  #sidebarCollapse span {
    display: none;
  }
  #content {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .content_inner,
  .content_header {
    max-width: 100%;
  }
}
@media (max-width: 991px) {
  h1.let-get {
    margin: 0 auto;
    text-align: center;
  }
  .Onboarding_box {
    width: 654px;
    padding: 30px;
  }
  .formBox_main {
    padding: 22px;
  }
  .w280 {
    width: 206px;
  }
  .w368 {
    margin-left: auto;
    width: calc(100% - 206px);
  }
  .filterList {
    right: 55px;
  }
  .headerLogin {
    display: none;
  }
  .bg_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .formBox_main {
    min-height: 100vh;
    border-bottom: 0 !important;
  }
  .table_width {
    width: 821px;
    overflow: auto;
  }
  .custom_table.table-wrapper {
    width: 900px;
    overflow: auto;
  }
  .custom_table table {
    width: 876px;
  }
  .themebtn-blue,
  .themebtn-dark {
    height: 56px;
    font-size: 17px;
  }
  .w-216 {
    width: 132px;
  }
  .NavIcon {
    width: 45px;
    height: 45px;
    margin-right: 10px;
  }
  .dashboard-body {
    margin-top: 42px;
  }
  .f24 {
    font-size: 20px;
  }
  .f16 {
    font-size: 13px;
  }
  .giro-table tbody tr td {
    font-size: 11px;
  }
  .giro-table td {
    padding: 10px !important;
  }
  .giro-table thead {
    height: 56px;
  }
  .f48 {
    font-size: 36px;
  }
  .mt-64 {
    margin-top: 42px !important;
  }
  .tabs-body ul {
    margin-bottom: 28px !important;
  }
}

@media (max-width: 768px) {
  .PasswordReset {
    width: 100%;
    padding: 37px;
    border-radius: 8px;
    background: #24262c;
    margin: 0 22px;
  }
  .OnboardingForm {
    flex-direction: column;
  }
  .Onboarding_box .w368 {
    width: 100%;
  }
  .Onboarding_box .form_box {
    width: 100%;
    margin: 0 auto;
  }
  .Onboarding_box .formStep.w280 {
    width: 100%;
  }
  .formStep ul::after {
    display: none;
  }
  .formStep ul li {
    font-size: 12px;
    margin-bottom: 32px;
    padding-left: 30px;
  }
  .formStep ul li::after,
  .formStep ul li.active::after {
    width: 19px;
    height: 19px;
    background-size: cover;
  }
  .Onboarding_box .formStep ul {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .Onboarding_box {
    width: 100%;
    padding: 30px 20px;
  }
  .formStep ul li span {
    color: unset;
    display: none;
  }
  .dashboard-body {
    margin-top: 40px;
  }
  .table_width {
    width: 821px;
    overflow: auto;
  }
  .custom_table.table-wrapper {
    width: auto;
    overflow: auto;
  }
  .custom_table table {
    width: 100%;
  }
  .dashboard_box_detail {
    margin-right: 0px;
    width: 48%;
  }
  .main_card_wrap {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .dashboard_box_detail {
    margin-right: 0;
  }
  .input-box .input-box-field .form-control {
    height: 52px;
  }
  .main_card_wrap {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .btn_passs button.themebtn-blue {
    width: 100%;
  }
  .f20 {
    font-size: 15px;
  }
  .PasswordReset {
    padding: 20px !important;
    margin: 60px 22px;
  }
  .dashboard_box_detail.Billing_card {
    width: 100%;
  }
  .f48 {
    font-size: 30px;
  }
  .Message_box {
    min-height: 106px !important;
  }
  .table_width {
    width: 821px;
    overflow: auto;
  }
  .form_box {
    width: 100%;
    margin: 0 auto;
  }
  .custom_table.table-wrapper {
    width: auto;
    overflow: auto;
  }
  .custom_table table {
    width: 100%;
  }
  .formBox_main {
    width: 100%;
    padding: 20px;
  }
  .formBox {
    border-radius: 16px;
    width: 100%;
    padding: 40px 20px 40px;
  }
  .f36 {
    font-size: 25px;
  }
  .mb-32 {
    margin-bottom: 25px !important;
  }
  .tabs-body button.nav-link {
    font-size: 16px !important;
  }
  .mb-none{
    display: none;
  }
  .custom-select{
    width: 85px !important;
  }
  .heading-limit-row{
    align-items: center !important;
  }
}

@media (max-width: 480px) {
  .dashboard_box_detail {
    margin-top: 9px;
  }
  .right_icon .NavIcon {
    margin-right: 0 !important;
    margin-left: 8px !important;
  }
  .creat-modal .modal-content {
    padding: 19px;
    width: calc(100% - 32px);
    min-height: 376px;
  }
  .close-icon {
    background: none;
    border: none;
    position: absolute;
    top: -36px;
    right: 14px;
  }
}

@media (max-width: 376px) {
  .dashboard_box_detail {
    margin-right: 0px;
    width: 100%;
  }
  .tabs-body button.nav-link {
    font-size: 14px !important;
  }
}

@media (max-width: 320px) {
}
