@font-face {
  font-family: 'AnebaNeue';
  src: url('./assets/fonts/AnebaNeue-Italic.eot');
  src: url('./assets/fonts/AnebaNeue-Italic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/AnebaNeue-Italic.woff2') format('woff2'),
      url('./assets/fonts/AnebaNeue-Italic.woff') format('woff'),
      url('./assets/fonts/AnebaNeue-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'AnebaNeue';
  src: url('./assets/fonts/AnebaNeue-Mediumitalic.eot');
  src: url('./assets/fonts/AnebaNeue-Mediumitalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/AnebaNeue-Mediumitalic.woff2') format('woff2'),
      url('./assets/fonts/AnebaNeue-Mediumitalic.woff') format('woff'),
      url('./assets/fonts/AnebaNeue-Mediumitalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'AnebaNeue';
  src: url('./assets/fonts/AnebaNeue-Medium.eot');
  src: url('./assets/fonts/AnebaNeue-Medium.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/AnebaNeue-Medium.woff2') format('woff2'),
      url('./assets/fonts/AnebaNeue-Medium.woff') format('woff'),
      url('./assets/fonts/AnebaNeue-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AnebaNeue';
  src: url('./assets/fonts/AnebaNeue-SemiBolditalic.eot');
  src: url('./assets/fonts/AnebaNeue-SemiBolditalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/AnebaNeue-SemiBolditalic.woff2') format('woff2'),
      url('./assets/fonts/AnebaNeue-SemiBolditalic.woff') format('woff'),
      url('./assets/fonts/AnebaNeue-SemiBolditalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'AnebaNeue';
  src: url('./assets/fonts/AnebaNeue-Bolditalic.eot');
  src: url('./assets/fonts/AnebaNeue-Bolditalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/AnebaNeue-Bolditalic.woff2') format('woff2'),
      url('./assets/fonts/AnebaNeue-Bolditalic.woff') format('woff'),
      url('./assets/fonts/AnebaNeue-Bolditalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'AnebaNeue';
  src: url('./assets/fonts/AnebaNeue-Bold.eot');
  src: url('./assets/fonts/AnebaNeue-Bold.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/AnebaNeue-Bold.woff2') format('woff2'),
      url('./assets/fonts/AnebaNeue-Bold.woff') format('woff'),
      url('./assets/fonts/AnebaNeue-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AnebaNeue';
  src: url('./assets/fonts/AnebaNeue-Regular.eot');
  src: url('./assets/fonts/AnebaNeue-Regular.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/AnebaNeue-Regular.woff2') format('woff2'),
      url('./assets/fonts/AnebaNeue-Regular.woff') format('woff'),
      url('./assets/fonts/AnebaNeue-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AnebaNeue';
  src: url('./assets/fonts/AnebaNeue-SemiBold.eot');
  src: url('./assets/fonts/AnebaNeue-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/AnebaNeue-SemiBold.woff2') format('woff2'),
      url('./assets/fonts/AnebaNeue-SemiBold.woff') format('woff'),
      url('./assets/fonts/AnebaNeue-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AnebaNeue';
  src: url('./assets/fonts/AnebaNeue-UltraLight.eot');
  src: url('./assets/fonts/AnebaNeue-UltraLight.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/AnebaNeue-UltraLight.woff2') format('woff2'),
      url('./assets/fonts/AnebaNeue-UltraLight.woff') format('woff'),
      url('./assets/fonts/AnebaNeue-UltraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AnebaNeue';
  src: url('./assets/fonts/AnebaNeue-UltraLightitalic.eot');
  src: url('./assets/fonts/AnebaNeue-UltraLightitalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/AnebaNeue-UltraLightitalic.woff2') format('woff2'),
      url('./assets/fonts/AnebaNeue-UltraLightitalic.woff') format('woff'),
      url('./assets/fonts/AnebaNeue-UltraLightitalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
body
{
  font-family: 'AnebaNeue';
  /* font-weight: bold; */
}
.scroll-container2{
  height: 100vh;
    overflow-y: scroll;
    display: block;
}
.scroll-container2::-webkit-scrollbar {
  width: 6px !important;
  /* display: none; */
}

.scroll-container2::-webkit-scrollbar-track {
  background: #252427 !important;
  border-radius: 10px !important;
  /* display: none; */

}

.scroll-container2::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  background: #475661 !important;
  /* display: none; */

}