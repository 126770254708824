@font-face {
  font-family: "Aneba Neue";
  src: url("../fonts/AnebaNeue-Bold.eot");
  src: local("../fonts/Aneba Neue Bold"), local("AnebaNeue-Bold"),
    url("../fonts/AnebaNeue-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/AnebaNeue-Bold.woff2") format("woff2"),
    url("../fonts/AnebaNeue-Bold.woff") format("woff"),
    url("../fonts/AnebaNeue-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Aneba Neue";
  src: url("../fonts/AnebaNeue-Medium.eot");
  src: local("../fonts/Aneba Neue Medium"), local("AnebaNeue-Medium"),
    url("../fonts/AnebaNeue-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/AnebaNeue-Medium.woff2") format("woff2"),
    url("../fonts/AnebaNeue-Medium.woff") format("woff"),
    url("../fonts/AnebaNeue-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Aneba Neue";
  src: url("../fonts/AnebaNeue-Regular.eot");
  src: local("../fonts/Aneba Neue"), local("AnebaNeue-Regular"),
    url("../fonts/AnebaNeue-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/AnebaNeue-Regular.woff2") format("woff2"),
    url("../fonts/AnebaNeue-Regular.woff") format("woff"),
    url("../fonts/AnebaNeue-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Aneba Neue";
  src: url("../fonts/AnebaNeue-SemiBold.eot");
  src: local("../fonts/Aneba Neue SemiBold"), local("AnebaNeue-SemiBold"),
    url("../fonts/AnebaNeue-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/AnebaNeue-SemiBold.woff2") format("woff2"),
    url("../fonts/AnebaNeue-SemiBold.woff") format("woff"),
    url("../fonts/AnebaNeue-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Aneba Neue";
  src: url("../fonts/AnebaNeue-UltraLight.eot");
  src: local("../fonts/Aneba Neue UltraLight"), local("AnebaNeue-UltraLight"),
    url("../fonts/AnebaNeue-UltraLight.eot?#iefix") format("embedded-opentype"),
    url("../fonts/AnebaNeue-UltraLight.woff2") format("woff2"),
    url("../fonts/AnebaNeue-UltraLight.woff") format("woff"),
    url("../fonts/AnebaNeue-UltraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

:root {
  --litgray: #cbd8e7;
  --yellow: #ffc120;
  --white: #fff;
  --darkblue: #160e24;
  --blue: #525197;
}

.blu {
  color: var(--blue);
}

.clr-blue {
  color: var(--litgray);
}

.clr-yellow {
  color: var(--yellow);
}

.bgdark {
  background: linear-gradient(90deg, #0e0527 0.71%, #0e0724 100%);
}

/* font-family */
.fw-200 {
  font-weight: 200;
  font-style: normal;
  font-family: "Aneba Neue";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0 !important;
}

.fw-600 {
  font-weight: 600;
  font-style: normal;
  font-family: "Aneba Neue";
}

.fw-500 {
  font-weight: 500;
  font-style: normal;
  font-family: "Aneba Neue";
}

.fw-n {
  font-style: normal;
  font-family: "Aneba Neue";
}

.fw-bold {
  font-style: bold;
  font-family: "Aneba Neue";
}

.align-center {
  text-align: center !important;
}
.btn {
  font-weight: bold;
  font-weight: 600;
}
/* border-radius */
.br-16 {
  border-radius: 16px;
}

* {
  margin: 0;
  padding: 0;
}

.menu_icon {
  display: none;
}

/*theme-btn start*/
.themebtn-blue {
  width: 112px;
  height: 64px;
  border-radius: 8px;
  background: radial-gradient(
    77.34% 77.34% at 50% 22.66%,
    #6ed3ff 0%,
    #2c0e82 100%
  );
  box-shadow: 0px 6px 10px 1px rgba(0, 0, 0, 0.32);
  border: none;
  font-family: "Aneba Neue";
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 137.3%;
}

.themebtn-dark {
  width: 112px;
  height: 64px;
  border-radius: 8px;
  background: radial-gradient(
    70.31% 70.31% at 50% 22.66%,
    #b6acbb 0%,
    #4c444e 41.67%,
    #252231 100%
  );
  box-shadow: 0px 15px 13px 1px rgba(0, 0, 0, 0.32) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: none;
  font-family: "Aneba Neue";
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 137.3%;
}

.themebtn-blue:hover {
  background: radial-gradient(
    70.31% 70.31% at 50% 22.66%,
    #b6acbb 0%,
    #4c444e 41.67%,
    #252231 100%
  );
  box-shadow: 0px 15px 13px 1px rgba(0, 0, 0, 0.32) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: white;
}

.themebtn-dark:hover {
  background: radial-gradient(
    77.34% 77.34% at 50% 22.66%,
    #6ed3ff 0%,
    #2c0e82 100%
  );
  box-shadow: 0px 6px 10px 1px rgba(0, 0, 0, 0.32);
  border: none;
  color: black;
}

/*theme-btn start end*/
body,
html {
  font-family: "Aneba Neue";
  font-size: 17px;
  min-height: 100%;
  height: auto;
  background: #1e1e1e;
  overflow-x: hidden;
  /* background-color: var(--darkblue) ; */
}

.support_btn.d-flex.align-items-center.mb_none_winngng {
  display: none;
}

ul li {
  list-style: none;
  padding: 0;
  margin: 0;
}

img {
  width: 100%;
}

ul {
  padding: 0 !important;
  margin: 0 !important;
}

/* theme-container */
.theme-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

/* margin class */
.mt-130 {
  margin-top: 130px !important;
}

.pt-32 {
  padding-top: 32px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mt-8 {
  margin-top: 8px !important ;
}
.mr-8 {
  margin-right: 8px !important;
}

.mr-16 {
  margin-right: 16px !important;
}

.mt-17 {
  margin-top: 17px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.ml-16 {
  margin-left: 16px;
}

.mr-27 {
  margin-right: 27px;
}

.mt-24 {
  margin-top: 24px;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.mt-32 {
  margin-top: 32px !important;
}

.mt-64 {
  margin-top: 64px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

/* all site-heading start*/
.f48 {
  color: #fff;
  font-size: 48px;
  font-family: "Aneba Neue";
  font-style: normal;
  font-weight: 600;
  line-height: 105.8%;
}

.f24 {
  color: #8e98b2;
  font-family: "Aneba Neue";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120.6%;
}

.f16 {
  color: #aaa;
  font-family: "Aneba Neue";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.f20 {
  color: #cbd8e7;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 105.8%; /* 21.16px */
}

.f36 {
  color: #fff;
  font-family: "Aneba Neue";
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 120.6%;
}
/* all site-heading end*/

.theme-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

/* header-dashboard */
.NavIcon {
  width: 56px;
  height: 55px;
  border-radius: 8px;
  background: #24262c;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
}

.right_icon .NavIcon {
  margin-right: 0 !important;
  margin-left: 16px !important;
}

.DashboardHeader:last-child .NavIcon {
  margin-right: 0;
}

.NavIcon img {
  width: 24px;
}

.w-216 {
  width: 216px;
}

.DashboardHeader button {
  color: #282160;
}

.content_inner,
.content_header {
  max-width: 98%;
/* overflow: auto; */
}

/* header login */
.loginlogo img {
  width: 88px;
}

.headerLogin {
  padding: 32px 0;
}

/* login-page */
.formBox_main {
  min-height: calc(100vh - 152px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
}

.bg_wrap {
  background: url("../images/bg.png");
  min-height: 100vh;
  background-size: cover;
}

.login-logo {
  width: 63px;
  height: 84px;
}

.formBox {
  border-radius: 16px;
  box-shadow: 0px 11px 19px -1px rgba(54, 62, 73, 0.24);
  background: linear-gradient(
    107deg,
    rgba(88, 88, 88, 0.7) 0%,
    rgba(9, 20, 39, 0.7) 100%
  );
  width: 448px;
  /* min-height: 617px; */
  padding: 55px 40px 64px;
  margin: 0 auto;
}

.input-box .input-box-field .form-control:focus {
  background: #111;
  background-color: #111 !important;
  border-color: #343d49 !important;
  outline: 0;
  box-shadow: none !important;
}

.filterbtn img {
  width: 20px;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.2rem) translateX(0.3rem);
  font-size: 14px;
}

.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  all: unset !important;
  color: white !important;
}

.input-box .input-box-field {
  margin-bottom: 5px;
}

.input-box .input-box-field .form-control {
  border-radius: 12px;
  border: 2px solid #343d49;
  background: #111;
  box-shadow: 0px 7px 8px 0px rgba(0, 0, 0, 0.16) !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  height: 64px;
  font-weight: 500;
  font-family: "AnebaNeue";
  font-style: normal;
  color: #cbd8e7 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 2px solid #343d49 !important;
  -webkit-text-fill-color: #cbd8e7 !important;
  /* -webkit-box-shadow: 0 0 0px 1000px #161126 inset !important; */
  transition: background-color 5000s ease-in-out 0s !important;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  opacity: 1;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  color: #ffff;
}

.form-floating > label {
  color: white !important;
}

.login-bottom h1 {
  cursor: pointer;
}

.login-bottom h1:hover {
  color: #6ed3ff;
}

.login-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-floating {
  position: relative;
}

.txt_flex span {
  display: inline;
  margin-left: 5px;
  cursor: pointer;
}

.hover:hover {
  color: #6ed3ff;
}

.showeye {
  position: absolute;
  right: 16px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  top: 50%;
  transform: translateY(-50%);
}

.back_btn img {
  width: 24px;
  cursor: pointer;
  margin-right: 6px;
}

.back_btn h1 {
  cursor: pointer;
}

.h-auto {
  min-height: auto !important;
}

/* dashbord */
/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.siderBar-header {
  text-align: center;
}

.siderBar-header img {
  width: 63px;
  height: 84px;
}

.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
  background: #1e1e1e;
}

.wrapper #sidebar {
  min-width: 300px;
  max-width: 300px;
  background: #1e1e1e;
  color: #fff;
  transition: all 0.3s;
  height: 100vh;
  overflow-y: auto;
  position: fixed;
  left: 0;
  padding: 32px;
}

.SideBar-list {
  margin-top: 64px;
}

.SideBar-list a {
  border-radius: 16px;
  width: 232px;
  height: 56px;
  color: #cbd8e7;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 125.8%;
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 2px;
  text-decoration: none;
  cursor: pointer;
}

.SideBar-list a img {
  width: 24px;
  margin-right: 16px;
}

.SideBar-list a.active {
  background: #24262c;
}

.SideBar-list a:hover {
  background: #24262c;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
#content {
  width: calc(100% - 300px);
  padding: 20px;
  min-height: 100vh;
  transition: all 0.3s;
  margin-left: auto;
  padding: 64px 0px 46px 10px;
}

.dashboard-body {
  margin-top: 81px;
}

.tabs-body {
  max-width: 430px;
  margin: 0 auto;
}

.tabs-body button.nav-link.active {
  border: 2px solid #585871;
  color: #cbd8e7;
  background-color: #24262c !important;
}

.tabs-body button.nav-link {
  border: 2px solid #24262c;
  background-color: #24262c !important;
  border-radius: 16px;
  color: #cbd8e7;
  text-align: center;
  padding: 16px !important;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 134.3%;
}

.tabs-body ul {
  margin-bottom: 64px !important;
}

.tabs-body {
  margin-top: 32px;
}

.tabs-body button.nav-link {
  margin-right: 16px;
}

.tabs-body button:last-child .nav-link {
  margin-right: 0;
}

.form_box {
  width: 368px;
  margin: 0 auto;
}

.form_box .input-box-field {
  margin-bottom: 8px;
}

.Message_box {
  min-height: 168px !important;
}

.error_box {
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 20px;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: 118.3%;
  margin-top: 32px;
}

.bg_success {
  background: #51ff82;
  color: #175328;
}

.bg_danger {
  background: #f04;
  color: #fff;
}

/* .dashboard-body */
.w-16 {
  width: 16px;
}

.bg_dark {
  background: #343d49;
}

.dashboard_box_detail .NavIcon {
  border-radius: 50px;
}

b {
  font-weight: bold !important;
}

.Billing_card {
  align-items: flex-start !important;
}

.txt-lit {
  color: #8e98b2 !important;
}

.dashboard_box_detail {
  border-radius: 8px;
  background: #24262c;
  padding: 16px;
  margin-right: 16px;
  width: 200px;
  max-width: 100%;
}

.dashboard_box_detail:last-child {
  margin-right: 0;
}

.mr-0 {
  margin-right: 0 !important;
}

.dashboard_box_detail {
  margin-top: 24px;
}

.dashboard_box_detail .NavIcon {
  border-radius: 50px;
  flex: 0 0 40px;
  height: 40px;
  margin-right: 16px;
  background: #343d49;
}

.main_card_wrap {
  display: inline-flex;
  flex-wrap: wrap;
}

.main_Wrap_card {
  justify-content: space-between;
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;
  width: 100%;
}

.filter_icon {
  position: relative;
}

.filterList {
  top: 0;
  position: absolute;
  /* width: 144px; */
  border-radius: 8px;
  background: #24262c;
  padding: 8px 16px;
  /* left: 72px; */
  right: 72px;
  left: auto;
}

.filterList ul li a:hover {
  color: #6ed3ff;
}
.selectedActiveItem {
  color: #6ed3ff !important;
}
.filterList ul li a {
  color: #8e98b2;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  text-decoration: none;
  margin-bottom: 8px;
  display: block;
  line-height: 161.3%;
}
.main_showLoader {
  position: relative;
}
.showLoader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(15, 16, 20, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

/* .custom_table */
.giro-table {
  border-collapse: separate;
  border-spacing: 0 14px;
}

.giro-table .giro-table tr {
  border: none;
}

.giro-table th .Invoice_link img {
  width: 24px;
  margin-right: 8px;
}

a.Invoice_link {
  padding: 0 !important;
  display: flex;
  align-items: center;
}

.Invoice_link {
  background: #33363e !important;
  font-weight: 600;
  font-size: 16px !important;
  line-height: 155.5%;
  color: #aab3d2 !important;
  vertical-align: middle;
  text-decoration: none;
}

.giro-table th {
  background: #33363e !important;
  font-weight: 600;
  font-size: 16px !important;
  line-height: 155.5%;
  color: #aab3d2 !important;
  padding: 8.7px 16px !important;
  vertical-align: middle;
  text-decoration: none;
}

.giro-table thead {
  border-radius: 8px !important;
}

.giro-table tbody tr td {
  background: #24262c !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 158.5%;
  color: #cbd8e7;
  vertical-align: middle;
}

.custom_table .redio_wrap input[type="radio"] + label::before,
.custom_table .redio_wrap input[type="checkbox"]:checked + label::after {
  border-radius: 50px;
}

.custom_table .redio_wrap input[type="radio"]:checked + label::after,
.custom_table .redio_wrap input[type="checkbox"]:checked + label::after {
  border-radius: 50px;
}

.custom_table .redio_wrap input[type="radio"] + label,
.custom_table .redio_wrap input[type="checkbox"] + label {
  color: #cbd8e7;
}

.br-r-8 {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.bl-r-8 {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.link-box {
  position: relative;
  border-radius: 8px;
  background: #17181d;
  padding: 10px;
  width: 300px;
  /* width: auto !important; */
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.heading-limit-row{
  display: flex;
  align-items: center;
  justify-content: right;
}
.copy_wrap {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.giro-table thead tr th:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.giro-table tbody tr td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.giro-table thead {
  border-radius: 8px !important;
  height: 72px;
}

.copy_wrap img {
  width: 24px;
  height: 24px;
}

.giro-table td {
  padding: 16px !important;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 !important;
  /* Remove default borders */
  border-top: none !important;
  /* Add custom border */
  border-bottom: none !important;
  /* Add custom border */
}

td:first-child,
th:first-child {
  border-left: none !important;
  /* Add custom border */
}

td:last-child,
th:last-child {
  border-right: none !important;
  /* Add custom border */
}

/* .custom_table end */
.w-352 {
  width: 352px;
}

.current-text {
  background: linear-gradient(180deg, #73c4ff 36.46%, #512bbd 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 36px;
  font-style: normal;
  font-weight: bold;
  line-height: 121.8%;
}

.dashboard_box_detail.Billing_card.align-items-center {
  align-items: self-start !important;
}

.icon-table img {
  width: 24px;
}

.icon-table {
  margin-left: 8px;
  width: 40px;
  border-radius: 50px;
  height: 40px;
  background: #111111;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search_inner {
  position: relative;
}

.input_wrap_search {
  position: absolute;
  right: 0;
  top: 0;
}

.input_wrap_search .form-group img {
  width: 24px;
}

.input_wrap_search .form-group img {
  width: 24px;
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
}

.input_wrap_search .form-group .search-input {
  width: 192px;
  height: 40px;
  border-radius: 16px;
  background: #111;
  border: 1px solid #111;
  outline: none;
  color: #8e98b2;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 168.523%;
  padding-left: 48px;
}

/* creat-modal */
/* .creat-modal {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    background: rgba(15, 16, 20, 0.80);   
}
.modal-overlay{
	width: 500px;
	position: relative;
	z-index: 9999;
} */
.creat-modal .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(15, 16, 20, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

.creat-modal .modal-content {
  border-radius: 16px;
  background: linear-gradient(107deg, rgb(88 88 88 / 100%) 0%, #091427 100%);
  box-shadow: 0px 11px 19px -1px rgba(54, 62, 73, 0.24);
  padding: 32px;
  width: 432px;
  min-height: 376px;
}

.close-icon {
  background: none;
  border: none;
  position: absolute;
  top: 0;
  right: -32px;
}

.h-56 {
  height: 56px !important;
}

.text_blue:hover {
  color: white !important;
}

.text_blue {
  color: #282160 !important;
}

/* Onboarding page */
.bg_dark {
  background: #0f1014;
}
.Onboarding_box {
  border-radius: 16px;
  background: linear-gradient(107deg, rgb(88 88 88 / 100%) 0%, #091427 100%);
  box-shadow: 0px 11px 19px -1px rgba(54, 62, 73, 0.24);
  width: 792px;
  min-height: 672px;
  padding: 64px;
}
.Onboarding_box h1 {
  max-width: 368px;
}

.formStep ul li {
  color: #cbd8e7;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 156.8%;
  margin-bottom: 32px;
  position: relative;
  padding-left: 37px;
}
.formStep ul li::after {
  content: "";
  background: url("../images/icon/inactive.png");
  width: 24px;
  height: 24px;
  position: absolute;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  z-index: 1;
}
.formStep ul li.active::after {
  background: url("../images/icon/activeIcon.png");
  background-repeat: no-repeat;
}
.formStep ul {
  position: relative;
  z-index: 1;
}
.formStep ul::after {
  content: "";
  width: 4px;
  height: 97%;
  background-color: #5f607a;
  left: 10px;
  top: 3px;
  position: absolute;
  z-index: -1;
}
.w280 {
  width: 280px;
}
.w368 {
  width: 374px;
}

.OnboardingForm {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
}
.form-box .form-btn {
  margin-top: 32px !important;
}

/* PasswordReset */
.PasswordReset_main {
  min-height: 100vh;
  display: flex;
  background: #0f1014;
  align-items: center;
  justify-content: center;
}
.PasswordReset {
  width: 600px;
  padding: 48px;
  border-radius: 8px;
  background: #24262c;
}

/* redio_wrap  . */
.redio_wrap input[type="radio"],
.redio_wrap input[type="checkbox"] {
  appearance: none;
}

.redio_wrap input[type="radio"] + label:hover,
.redio_wrap input[type="checkbox"] + label:hover {
  color: #6ed3ff;
}

.txt-blue {
  color: #6ed3ff !important;
}

.redio_wrap input[type="radio"] + label,
.redio_wrap input[type="checkbox"] + label {
  cursor: pointer;
  position: relative;
  padding-left: 24px;
  line-height: 20px;
  color: #aaa;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.redio_wrap {
  cursor: pointer;
}

.redio_wrap input[type="radio"] + label::before,
.redio_wrap input[type="checkbox"] + label::before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  aspect-ratio: 1;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  opacity: 1;
  transition: all 0.3s;
  border-radius: 4px;
  border: 2px solid #5f607a;
}

.redio_wrap input[type="radio"] + label::after,
.redio_wrap input[type="checkbox"] + label::after {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  aspect-ratio: 1;
  border-radius: 2px;
  background: #69e7e4;
  position: absolute;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: all 0.3s;
}

.redio_wrap input[type="radio"]:checked + label::after,
.redio_wrap input[type="checkbox"]:checked + label::after {
  opacity: 1;
}

/* placeholder */
::-webkit-input-placeholder {
  /* Edge */
  color: #4e4e63;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #4e4e63;
}

::placeholder {
  color: #4e4e63;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.scrollable-container {
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
  /* For Firefox */
  scrollbar-color: transparent transparent;
  /* For Firefox */
  -ms-overflow-style: none;
  /* For IE and Edge */
}

.scrollable-container::-webkit-scrollbar {
  width: 0.5em;
  /* Adjust the width as needed */
}

.scrollable-container::-webkit-scrollbar-track {
  background: transparent;
  /* Set the background color as needed */
}

.scrollable-container::-webkit-scrollbar-thumb {
  background-color: transparent;
  /* Set the thumb color as needed */
}

.btn_load_more {
  width: 239px;
  height: 48px;
  background-color: #33363e;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px auto 0;
  color: #cbd8e7;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 133.6%;
  border: none;
}
.btn_load_more:hover {
  color: black;
  background: radial-gradient(
    77.34% 77.34% at 50% 22.66%,
    #6ed3ff 0%,
    #2c0e82 100%
  );
}

.themeButton {
  height: 56px !important;
  color: #282160;
}
.themeButtonLedger {
  height: auto !important;
  width: auto !important;
  padding: 0.5rem 1rem !important;
  color: #282160;
  border-radius: 8px;
  background: radial-gradient(
    77.34% 77.34% at 50% 22.66%,
    #6ed3ff 0%,
    #2c0e82 100%
  );
  box-shadow: 0px 6px 10px 1px rgba(0, 0, 0, 0.32);
  border: none;
  font-family: "Aneba Neue";
  font-weight: 600;
}
.themeButtonDark {
  height: 56px !important;
}
.themeButtonDark:hover {
  color: #282160 !important;
}

.getHelpFormBox {
  width: 400px !important;
}

.f16b {
  color: #cbd8e7;
  font-family: "Aneba Neue";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 156.8%;
}

.f16a {
  color: #cbd8e7;
  font-family: "Aneba Neue";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  /* line-height: 168.523%; */
}

.filterDateBox {
  width: 327px;
  display: flex;
}

.data-form-control {
  width: 90% !important;
  background-color: #262626 !important;
  color: #c9cbd1 !important;
  border: 1px solid #8e98b2 !important;
}

.data-form-control:focus {
  background-color: #24262c;
}

.date-themebtn-blue {
  /* width: 112px;
  height: 64px; */
  border-radius: 8px;
  background: radial-gradient(
    77.34% 77.34% at 50% 22.66%,
    #6ed3ff 0%,
    #2c0e82 100%
  );
  color: #282160;
  box-shadow: 0px 6px 10px 1px rgba(0, 0, 0, 0.32);
  border: none;
  font-family: "Aneba Neue";
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* font-size: 24px; */
  font-style: normal;
  font-weight: 600;
  line-height: 137.3%;
}

.date-themebtn-blue:hover {
  background: radial-gradient(
    70.31% 70.31% at 50% 22.66%,
    #b6acbb 0%,
    #4c444e 41.67%,
    #252231 100%
  );
  box-shadow: 0px 15px 13px 1px rgba(0, 0, 0, 0.32) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: white;
}

.data-form-control::placeholder {
  color: #8e98b2 !important;
}

@media screen and (max-width: 680px) {
  .filterDateBox {
    width: 127px;
    display: flex;
    flex-direction: column;
  }
  .data-form-control {
    width: 100% !important;
    margin-bottom: 0.5rem;
  }
}

@media screen and (max-width: 375px) {
  .table_width {
    width: 740px !important;
    overflow: auto;
  }
}

@media screen and (max-width: 460px) {
  .getHelpFormBox {
    width: auto !important;
  }
}

.css-13cymwt-control {
  background: transparent !important;
  border: none !important;
}

.state__input-container .css-qbdosj-Input {
  color: white !important;
}

.state__control--is-focused {
  background: transparent !important;
  border: none !important;
}

#react-select-3-input {
  color: white !important;
}

.table-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50dvh;
}

/* .custom-pagination {
  width: 100%;
} */

/* background: radial-gradient(
    77.34% 77.34% at 50% 22.66%,
    #6ed3ff 0%,
    #2c0e82 100%
  ); */
/* .custom-pagination .page-item.active .page-link {
 
  background-color: #007bff;
  border-color: #007bff;
  box-shadow: 0px 6px 10px 1px rgba(0, 0, 0, 0.32);
  color: white;
  border-radius: 50%;
  padding: 5px 12px;
}

.custom-pagination .page-link {
  color: white;
  background-color: transparent;
  border: none;
}

.custom-pagination .page-item.disabled .page-link {
  color: rgba(255, 255, 255, 0.5);
}

.custom-pagination .page-item .page-link {
  color: #ffffff;
  padding: 5px 12px;
  border-radius: 50%;
  transition: background-color 0.3s, color 0.3s;
}

.custom-pagination .page-item .page-link:hover {
  background-color: rgba(255, 255, 255, 0.2);
  color: #ffffff;
}

.custom-pagination .pagination-ellipsis {
  color: rgba(255, 255, 255, 0.75);
  background: teal;
  padding: 5px 12px;
 
} */
.custom-pagination .page-item .page-link {
  color: #ffffff;
  background-color: transparent;
  border: none;
  width: 40px; /* Fixed width */
  height: 40px; /* Fixed height */
  line-height: 40px; /* Center text vertically */
  padding: 0; /* Remove padding */
  text-align: center; /* Center text horizontally */
  border-radius: 50%; /* Circular shape */
  transition: background-color 0.3s, color 0.3s;
  display: inline-block;
  margin: 0px 4px;
}

.custom-pagination .page-item .page-link:hover,
.custom-pagination .page-item.active .page-link {
  background: radial-gradient(
    77.34% 77.34% at 50% 22.66%,
    #6ed3ff 0%,
    #2c0e82 100%
  ); /* Blue color for active or hovered */
  color: white;
  border-radius: 50%;
}

.custom-pagination .page-item.disabled .page-link {
  color: rgba(255, 255, 255, 0.5);
  background-color: #2e2e2e; /* Match the background or desired shade */
  border-radius: 50%;
}

.custom-pagination .page-item .page-link:focus {
  outline: none; /* Remove the default outline */
  background-color: transparent; /* Ensure background stays blue when focused */
  color: white;
  border-radius: 50%;
  border: none !important;
  box-shadow: none !important;
}

.custom-pagination .page-item.prev .page-link,
.custom-pagination .page-item.next .page-link {
  border-radius: 50%; /* Ensure perfectly rounded corners */
  background-color: #2e2e2e; /* Background color to match the dark theme */
}

.custom-pagination .pagination-ellipsis {
  color: rgba(255, 255, 255, 0.75);
  padding: 8px 12px;
}

.pagination-container {
  width: 100%;
}
.table_width3 {
  overflow: auto !important;
  max-height: 700px !important;
  width: auto !important;
}
.pointer{
  cursor: pointer !important;
}
#tokenExCardNumber, #tokenExCardCvv{
  max-height: 70px;
}
.nav-item{
  margin: 2px !important;
}