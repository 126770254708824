@media (max-width: 1367px) {
  .theme-container {
    max-width: 1150px;
  }
}

@media (max-width: 1199px) {
  .theme-container {
    max-width: 950px;
  }
}
@media (max-width: 991px) {
  .theme-container.tabs-menu {
    width: 1340px;
    overflow-x: auto;
  }
  .theme-container.tabs-menu ul {
    overflow-x: auto;
    width: 1340px;
  }
  .theme-container {
    max-width: 900px;
  }
  .lobby-bg.HeaderLobby-wrap .theme-container.mt-60 {
    margin-top: 0;
    padding-top: 60px;
  }
}

@media (max-width: 768px) {
  .lobby-bg.HeaderLobby-wrap .theme-container.mt-60 {
    margin-top: 0;
    padding-top: 50px;
  }
  .Editprofile .f48 {
    font-size: 36px;
  }
}

@media (max-width: 575px) {
  .EditProfile-wrap form.fomBox {
    margin-top: 0;
  }
  .fomBox {
    padding-bottom: 41px;
  }
  .add_cashout_btnWrap {
    margin-top: 0px !important;
  }
}

@media (max-width: 480px) {
  .theme-container.Editprofile.z-9 {
    padding: 25px 10px;
  }
  .fomBox {
    width: 100%;
  }
  .input_wrap textarea.form-control {
    min-height: 144px;
  }
}

@media (max-width: 376px) {
  .theme-container.Editprofile.z-9 {
    padding: 0 10px;
  }
}

.z-9 {
  position: relative;
  z-index: 9;
}

.theme-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.mobile_notiffaction .theme-container.z-9 {
  padding: 0;
}

.fomBox {
  width: 400px;
  margin: 32px auto;
  padding-bottom: 100px;
}

.add_cashout_btnWrap h2 {
  font-weight: 600;
  color: #d2dedd;
}
.add_cashout_btnWrap p {
  color: #d2dedd;
  font-weight: 400;
}
.mb-0 {
  margin-bottom: 0px;
}

.swich_cashout_btn {
  width: 100%;
  padding: 10px 0px;
  margin-bottom: 16px;
  display: flex;
}
.swich_cashout_btn button {
  padding: 8px 14px;
  margin-right: 10px;
  border-radius: 12px;
  height: 45px;
  background: #2e363c;
  color: #d2dedd;
  border: 2px solid #484a4e !important;
}
.swich_cashout_btn button.active {
  border: 2px solid #4965b9 !important;
}

.f20 {
  font-weight: 500;
  font-style: normal;
  font-family: "Aneba Neue";
  font-size: 20px;
  line-height: 119.3%;
  color: var(--litgray);
}

.mt-32 {
  margin-top: 32px;
}

.user_cards_wrap {
  border: 2px solid transparent;
  margin-bottom: 8px;
  cursor: pointer;
}
.user_cards_wrap.active {
  border: 2px solid #4965b9;
  border-radius: 12px;
}
.user_cards_wrap .user_card {
  height: 72px;
  display: flex;
  align-items: center;
  border-radius: 12px;
  background: rgba(19, 21, 29, 0.68);
}
.user_cards_wrap:hover {
  border: 2px solid #4965b9;
  border-radius: 12px;
}

.user_card .card_img {
  width: 20%;
  justify-content: center;
}
.user_card .card_img img {
  height: 31px;
  width: 44px;
}
.user_card .card_details {
  width: 60%;
}
.user_card .card_details p {
  margin-bottom: 0px;
}

/* end */

.btn_opc_70 {
  opacity: 0.7 !important;
}
.user_card .card_details h2 {
  margin-bottom: 0px;
}
.user_card .delete_card {
  width: 20%;
  height: 24px;
}
.user_card .delete_card img {
  width: 24px;
}

.cashout_btn_wrap .cashout_btn {
  height: 64px;
  width: 100%;
  border-radius: 8px;
  color: #d2dedd;
  text-align: center;
  border: 1px solid #848484;
  background: radial-gradient(
    70.31% 70.31% at 50% 22.66%,
    #999 0%,
    #474747 41.67%,
    #1e1d22 100%
  );
  box-shadow: 0px 15px 13px 1px rgba(0, 0, 0, 0.32) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-width: 1px 1px 0 1px;
}

.main-wrap-form .themebtn-red:hover {
  background: radial-gradient(
    70.31% 70.31% at 50% 22.66%,
    #b6acbb 0%,
    #4c444e 41.67%,
    #252231 100%
  );
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px 15px 13px 1px rgba(0, 0, 0, 0.32);
  color: white !important;
}

.main-wrap-form .themebtn-red {
  background: radial-gradient(
    77.34% 77.34% at 50% 22.66%,
    #fff6e0 0%,
    #ffd66e 8.85%,
    #ff0044 100%
  );
  box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.32);
  color: #57100c !important;
}

/* .main-wrap-form .themebtn-dark {
  
  background-size: cover;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px 15px 13px 1px rgba(0, 0, 0, 0.32);
} */

/* .main-wrap-form .themebtn-dark:hover {
  border-radius: 8px;
  background: radial-gradient(
    100% 100% at 50% 0%,
    #ffd951 6.77%,
    #cb4848 99.48%
  );
  box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.32);
}  */
.themebtn-red {
  width: 112px;
  height: 40px;
  border-radius: 8px;
  background: radial-gradient(
    100% 100% at 50% 0%,
    #ffd951 6.77%,
    #cb5f48 99.48%
  ) !important;
  box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.32);
  border: none;
  border-radius: 8px;
  font-family: "Aneba Neue";
  font-size: 16px;
  line-height: 161.8%;
  text-align: center;
  color: #570f0b !important;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-style: normal;
}

.main-wrap-form {
  margin-top: 24px;
}

.main-wrap-form button {
  width: 100%;
  height: 64px !important;
}

.main-wrap-form .support_btn {
  margin-top: 64px;
}
.main-wrap-form .form-floating {
  margin-bottom: 8px;
}

.main-wrap-form .form-floating > label {
  font-family: "AnebaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 25px;
  color: white;
  opacity: 1 !important;
}

.main-wrap-form .form-control:focus {
  color: #cbd8e7 !important;
}

.main-wrap-form .form-control:focus ~ label {
  font-family: "AnebaNeue";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 23px;
  opacity: 1 !important;
  color: #ffffff !important;
  box-shadow: none !important;
}

.form-control:focus {
  color: #fff;
  background-color: #030303 !important;
  border-color: #343d49 !important;
  outline: 0;
  box-shadow: none !important;
}
.input_wrap textarea.form-control {
  min-height: 168px;
}
.input_wrap .form-control {
  background: #030303;
  border: 2px solid #484a4e;
  box-shadow: 0px 7px 8px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  height: 64px !important;
  font-family: "AnebaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px !important;
  color: #7a7e86;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: #fff !important;
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.item-wrap .img_icon {
  position: absolute;
  top: 14px;
  right: 15px;
  z-index: 99;
}

.item-wrap .img_icon.liked .heartfill {
  display: block !important;
}

button.img_icon {
  outline: none;
}

.item-wrap .img_icon.disliked .heartfill {
  display: none;
}

.item-wrap .img_icon .heartfill {
  display: none !important;
}

.img_icon.liked .heart {
  display: none;
}

button.img_icon.like img.heartfill {
  display: block !important;
}

button.img_icon.like img.heart {
  display: none !important;
}

.img_icon {
  background: none;
}
.img_icon_card_cashout {
  height: 32px;
  width: 48px;
  position: absolute;
  top: 16px;
  right: 16px;
}

.expire_wrapper_cashout {
  display: flex;
  justify-content: space-between;
}
.w49 {
  width: 49%;
}
.expire_wrapper_cashout .expire_wrap_inner {
  width: 55%;
}
.expire_wrapper_cashout .cvv_wrap_inner {
  width: 42%;
}

.support_btn .cashout-btn {
  font-size: 24px !important;
}
.support_btn .cashout-btn {
  width: 48%;
}

.cash-out-outer-btn button {
  width: 100%;
  height: 64px;
}
.cashout_withdraw_btn_wrap {
  display: flex;
  flex-direction: column;
}

.input-lab-p-abs {
  font-size: 9px !important;
  top: -13px !important;
}

.select-type-cus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 93%;
  padding: 26px 0px 10px;
  margin: 0px auto;
}

.select-cus-input {
  width: 100%;
  background: #030303;
  border: 2px solid #484a4e;
  border-radius: 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #7a7e86;
  padding: 0px 0px;
  cursor: pointer;
  z-index: 99;
}
.select-cus-input img {
  height: 24px;
  width: 24px;
}

.options-dev {
  z-index: 99;
  width: 100%;
  left: 0;
}
.options-dev .cus_option {
  border-bottom: 2px solid #484a4e;
  border-top: 2px solid #484a4e;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #cbd8e7;
  padding: 18.5px 10px;
  cursor: pointer;
}
.options-dev .cus_option:hover {
  background: rgba(19, 21, 29, 0.48);
}

.no-border {
  border: none !important;
}

.f24 {
  font-weight: 600;
  font-style: normal;
  font-family: "Aneba Neue";
  font-size: 24px;
  line-height: 119.3%;
  color: var(--litgray);
}

.blanc-conainer-wrap {
  border-radius: 8px;
  background: #24262c;
  padding: 16px 0px;
}
.blanc-conainer-inner {
  width: 90%;
  margin: auto;
}
.blanc-conainer-inner p {
  width: 85%;
  font-weight: 500;
  color: #cbd8e7;
  margin-top: 8px;
  margin-bottom: 14px;
}
.cur_blc_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cur_blc_wrap p {
  margin-bottom: 0px;
  margin-top: 0px;
  color: #d2dedd;
  font-weight: 600;
}
.headeing-wrap h1 {
  font-weight: 600 !important;
}
.headeing-wrap p {
  font-weight: 500;
}
.blanc-conainer-wrap .setting-img img {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.blanc-conainer-wrap .bln-con-img {
  width: 20%;
}
.blanc-conainer-wrap .bln-con-img .cir-cash {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0px auto;
  background: #343d49;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.blanc-conainer-wrap .bln-con-img .cir-cash img {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.blanc-conainer-wrap .cur-blnce-cash {
  width: 70%;
}
.blanc-conainer-wrap .cur-blnce-cash .golden-pri {
  width: 200px;
  align-items: center;
}
.blanc-conainer-wrap .cur-blnce-cash .golden-pri h1 {
  background: linear-gradient(180deg, #fee994 36.46%, #da6320 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0px;
}
.blanc-conainer-wrap .cur-blnce-cash .golden-pri img {
  width: 32px;
  height: 32px;
  margin-right: 5px;
}
.blanc-conainer-wrap .cur-blnce-cash p {
  margin-bottom: 0px;
}

.confirm-cash-wrapp .cash-bln {
  color: #d2dedd;
  margin: 32px 0px 16px;
}
.confirm-cash-wrapp .cash-equivalent {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.confirm-cash-wrapp .cash-equivalent h2 {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.confirm-cash-wrapp .cash-equivalent p {
  color: #d2dedd;
}
.add_cashout_btnWrap h2 {
  font-weight: 600;
  color: #d2dedd;
}
.add_cashout_btnWrap p {
  color: #d2dedd;
  font-weight: 400;
}
/* .swich_cashout_btn {
  width: 100%;
  padding: 10px 0px;
  margin-bottom: 16px;
  display: flex;
} */
/* .swich_cashout_btn button {
  padding: 8px 14px;
  margin-right: 10px;
  border-radius: 12px;
  height: 45px;
  background: #2e363c;
  color: #d2dedd;
} */
/* .swich_cashout_btn button.active {
  border: 2px solid #484a4e !important;
} */
.table_width2 {
  overflow: auto !important;
  max-height: 450px !important;
  width: auto !important;
}

.table_width2::-webkit-scrollbar {
  width: 4px !important;
  height: 10px !important;
  position: relative;
  bottom: -12px;
}

.table_width2::-webkit-scrollbar-track {
  background: #252427;
  border-radius: 10px;
}

.table_width2::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #36424a;
}

.table_width3::-webkit-scrollbar {
  width: 4px !important;
  height: 10px !important;
  position: relative;
  bottom: -12px;
}

.table_width3::-webkit-scrollbar-track {
  background: #252427;
  border-radius: 10px;
}

.table_width3::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #36424a;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 !important;
  /* Remove default borders */
  border-top: none !important;
  /* Add custom border */
  border-bottom: none !important;
  /* Add custom border */
}

.load_more {
  width: 239px;
  font-family: "Aneba Neue";
  height: 48px;
  margin-top: 100%;
  background-color: #33363e;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 133.6%;
  text-align: center;
  color: #cbd8e7;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none !important;
}

.load_more:hover {
  color: black;
  background: radial-gradient(
    77.34% 77.34% at 50% 22.66%,
    #6ed3ff 0%,
    #2c0e82 100%
  );
}

.custom-search-input::placeholder {
  color: #928f8f !important; /* White color for the placeholder */
  opacity: 1; /* Ensures full opacity */
}

.limit-span{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  margin-top: 16px;
}